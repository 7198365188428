export default function IconfCar() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect opacity="0.01" x="4" y="4" width="24" height="24" fill="black" />
            <path
                d="M20.2465 20.3726H11.6917M8.89058 20.3726H6.78431C6.35115 20.3726 6 20.0214 6 19.5883V16.5652C6 16.4906 6.02127 16.4176 6.06131 16.3547L8.62952 12.3189C8.70151 12.2058 8.82629 12.1373 8.96037 12.1373H10.1176C10.2259 12.1373 10.3137 12.0495 10.3137 11.9412V9.78432C10.3137 9.35115 10.6649 9 11.098 9H25.2157C25.6489 9 26 9.35115 26 9.78432V19.5883C26 20.0214 25.6489 20.3726 25.2157 20.3726H23.0688"
                stroke="#050505"
                strokeWidth="1.6"
                strokeLinecap="round"
            />
            <path
                d="M10.3135 22.7261C11.1798 22.7261 11.8821 22.0238 11.8821 21.1575C11.8821 20.2912 11.1798 19.5889 10.3135 19.5889C9.44717 19.5889 8.74487 20.2912 8.74487 21.1575C8.74487 22.0238 9.44717 22.7261 10.3135 22.7261Z"
                stroke="#050505"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M21.6865 22.7261C22.5529 22.7261 23.2552 22.0238 23.2552 21.1575C23.2552 20.2912 22.5529 19.5889 21.6865 19.5889C20.8202 19.5889 20.1179 20.2912 20.1179 21.1575C20.1179 22.0238 20.8202 22.7261 21.6865 22.7261Z"
                stroke="#050505"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path d="M9.9219 14.687L8.35327 16.8439" stroke="black" strokeWidth="1.4" strokeLinecap="round" />
            <path d="M12.6665 9.06299V17.2345C12.6665 17.6676 13.0177 18.0188 13.4508 18.0188H25.9998" stroke="#050505" strokeWidth="1.5" />
            <path d="M16.1965 11.7461V14.8834" stroke="black" strokeWidth="1.4" strokeLinecap="round" />
            <path d="M19.333 11.7461V14.8834" stroke="black" strokeWidth="1.4" strokeLinecap="round" />
            <path d="M22.4705 11.7461V14.8834" stroke="black" strokeWidth="1.4" strokeLinecap="round" />
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="black" />
        </svg>
    );
}
